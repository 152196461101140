
.flash-message {
    background-color: cornflowerblue;
    color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    border: black;
    animation-name: fade-animation;
    animation-delay: var(--animDelay);
    animation-duration: var(--animDuration);
    animation-fill-mode: forwards;
}

@keyframes fade-animation {
    0%{
        opacity: 1;
    }
    100% {
        display: none;
        opacity: 0;
    }
}

.success-flash-message {
    background-color: rgb(77, 179, 77);
}

.failure-flash-message {
    background-color: rgb(228, 91, 91);
}