
.subscriber-table-name-data-cell {
    width: 16%;
}
.subscriber-table-description-data-cell {
    width: 18%;
}
.subscriber-table-resource-name-data-cell {
    width: 18%;
}
.subscriber-table-iam-role-name-data-cell {
    width: 8%;
}
.subscriber-table-s3-bucket-data-cell {
    width: 8%;
}
.subscriber-table-download-limits-data-cell {
    width: 8%;
}
.subscriber-table-replay-limits-data-cell {
    width: 8%;
}
.subscriber-table-replay-enabled-data-cell {
    width: 8%;
}
.subscriber-table-download-enabled-data-cell {
    width: 8%;
}
.subscriber-table-livestream-enabled-data-cell {
    width: 8%;
}
.subscriber-table-nats-account-name-data-cell {
    width: 18%;
}
.subscriber-table-nats-username-data-cell {
    width: 18%;
}
.subscriber-table-action-data-cell {
    width: 12%;
}
