
@media screen and (max-width: 1000px) {
  html {
    font-size: 6pt;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  html {
    font-size: 8pt;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1366px) {
  html {
    font-size: 10pt;
  }
}
@media screen and (min-width: 1367px) and (max-width: 1500px) {
  html {
    font-size: 12pt;
  }
}

@media screen and (min-width: 1501px) {
  html {
    font-size: 14pt;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
    cursor: pointer;
    display: inline-block;
    margin: 0.5rem;
    border-radius: 0.2rem;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
}

.update-button-container {
    display: inline-flex;
    justify-content: flex-end;
    padding: 0.1rem 0.2rem;
}
.create-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 0.1rem 0.2rem;
}
.delete-button-container {
    display: inline-flex;
    justify-content: flex-start;
    padding: 0.1rem 0.2rem;
}

.cancel-button, .download-button {
    background-color: #b6b6b6;
    border-radius:8px;
    border:1px solid #868686;
    display:inline-block;
    cursor:pointer;
    color: #414141;
    font-family:Helvetica Neue;
    font-size:15px;
    padding:7px 18px;
    text-decoration:none;
}

.update-button, .create-button, .delete-button, .download-button {
    color: white;
    margin: 0;
}

.update-button, .create-button {
    background-color: #07882e;
    border-radius:8px;
    border:1px solid #138328;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Helvetica Neue;
    font-size:15px;
    padding:7px 18px;
    text-decoration:none;
}
.update-button:hover, .create-button:hover {
    background-color: #05b205;
}
.update-button:active, .create-button:active {
    position:relative;
    top:1px;
}


.download-button {
  background-color: rgb(53, 96, 212)
}

.delete-button {
    background-color: #8c0000;
    border-radius:8px;
    border:1px solid #8f1414;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Helvetica Neue;
    font-size:15px;
    padding:7px 18px;
    text-decoration:none;
}
.delete-button:hover {
    background-color: #b20000;
}
.delete-button:active, .cancel-button:active  {
    position:relative;
    top:1px;
}
.cancel-button:hover {
    background-color: #b0b0b0;
}

*[class$='toggle-button'] {
    float: left;
}

*[class$='toggle-button'] > .toggle-button-option {
    display: inline-block;
    padding: 0.2rem 0.1rem;
    border: 0.2rem solid darkgrey;
    width: 6rem;
}

*[class$='toggle-button'] > .toggle-button-selected {
    background-color: grey;
}

.table-data-cell {
    vertical-align: middle;
}

*[class*='-table-'][class$='input'] {
    font-size: 1rem;
    box-sizing: border-box;
    width: 95%;
}

.control-box {
    float: right;
}
