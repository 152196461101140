
.subscription-table-datapoint-data-cell {
    width: 30%;
    padding: 0.2rem;
}

.subscription-table-source-data-cell {
    width: 30%;
    padding: 0.2rem;
}
 
.subscription-table-subscriber-data-cell {
    width: 30%;
    padding: 0.2rem;
}

.subscription-table-action-data-cell {
    width: 10%;
}

