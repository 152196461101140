
.logo {
    float: left;
    vertical-align: middle;
    width: 160px;
    padding-left: 10px;
}

.header {
    overflow: hidden;
    width: 100%;
    background-color: #2e2e2e;
    padding: 10px 0px;
    height: 35px;
    top: 0;
    left: 0;
    position: fixed;
    border-bottom: 2px solid grey;
    z-index: 1000;
}

.header-left {
    position: fixed;
    top: 0;
    left: 160px;
    padding: 20px;
}

.header-right {
    float: right;
}

.amplify-button {
    font-size: 20px;
    padding: 1rem ;
    background: none;
    border: 1px lightgrey;
}

.amplify-button:hover {
    background: gray;
}

.logoutButton {
    background-color:#212121;
    border-radius:8px;
    border:1px solid #212121;
    display:inline-block;
    cursor:pointer;
    color:#ffffff;
    font-family:Helvetica Neue;
    font-size:15px;
    padding:10px 15px;
    text-decoration:none;
    text-shadow:3px 1px 0px #36383b;
}
.logoutButton:hover {
    background-color:#3c3d3d;
}
.logoutButton:active {
    position:relative;
    top:1px;
}

.current-location{
    color:lightgrey;
}