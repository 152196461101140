

.download-table-subscriber-data-cell {
    width: 45%;
    padding: 0.2rem;
}
.download-table-event-name-data-cell {
    width: 45%;
    padding: 0.2rem;
}
.download-table-download-data-cell {
    width: 10%;
    padding: 0.2rem;
}

.download-view-container {
    display: inline-flex;
    justify-content: flex-end;
}

.download-instructions {
    padding: 1rem;
    display: inline-block;
    text-align: left;
}

.download-code {
    background-color: lightgrey;
    border-radius: .1rem;
    padding: .2rem;
}