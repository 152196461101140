
.table-container {
    display: block;
    background-color: white;
}

.table-header {
    display: inline-block;
    height: 2rem;
    width: 100%;
}

.table-controls {
    display: inline-block;
    width: 100%;
    text-align: right;
    padding: 0.2rem 0;
}

.table {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
}

.table-column-header-cell {
    display: table-cell;
    border: 0.1rem solid grey;
    border-right: none;
    border-bottom: none;
    text-align: left;
    padding: .2rem;
    font-weight: 500;
    font-size: 1.2rem;
}

.table-row {
    display: table-row;
}

.table-data-cell {
    display: table-cell;
    border: 2px solid grey;
    border-right: none;
    border-bottom: none;
}

.table-create-cell {
    background-color: rgb(147, 178, 247)
}

.table-row:last-child > * {
    border-bottom: 2px solid grey;
}

.table-column-header-cell:last-child, .table-data-cell:last-child {
    border-right: 2px solid grey;
}

.button.disabled {
    opacity: 0.35;
    cursor: default;
}

.table-filter-text-container {
    display: inline-block;
    height: 1.5rem;
    width: 30%;
    padding: 0.2rem 0;
}

.table-filter-text-input {
    height: 1.5rem;
    font-size: 1rem;
    width: 95%;
}

.table-filter-text-input::placeholder {
    font-size: 1rem;
}

.paginator {
    display: inline-block;
    width: 70%;
}

.paginator-page-info {
    display: inline-block;
}

.paginator-controls {
    padding: 0 0.2rem;
    width: 28%;
    display: inline-block;
}

.paginator-controls > .paginator-button {
    width: 1.5rem;
    background: lightgrey;
    margin: 0.1rem;
    padding: 0.2rem 0;
    justify-content: center;
    border-radius: 0.2rem;
    text-align: center;
}

.paginator-selected-page-number {
    width: 1.5rem;
    display: inline-block;
    background-color: rgb(49, 160, 224);
    margin: 0.1rem;
    padding: 0.2rem 0;
    justify-content: center;
    border-radius: 0.2rem;
    text-align: center;
}

.paginator-elipses {
    width: 1.5rem;
    background: lightgrey;
    display: inline-block;
    cursor: default;
    margin: 0.1rem;
    padding: 0.2rem 0;
    justify-content: center;
    border-radius: 0.2rem;
    text-align: center;
    opacity: 0.35;
}

.paginator-page-number {
    display: inline-block;
}

.loading {
    border: none;
    border-top: 2px solid grey;
    text-align: left;
    font-weight: 600;
    font-size: 1.5rem;
    padding: 1rem;
}

@keyframes success-fade-anim {
    from {background-color: rgb(77, 179, 77);}
    to {background-color: white;}
}

@keyframes failure-fade-anim {
    from {background-color: rgb(228, 91, 91);}
    to {background-color: white;}
}

.operation-success {
    animation: success-fade-anim var(--animDuration) var(--animDelay) both;
}

.operation-failure {
    animation: failure-fade-anim var(--animDuration) var(--animDelay) both;
}