
.source-table-name-data-cell {
    width: 30%;
}
.source-table-description-data-cell {
    width: 30%;
}
.source-table-vehicle-id-data-cell {
    width: 10%;
}
.source-table-datapoint-tags-data-cell {
    width: 20%;
    text-align: left;
}
.source-table-action-data-cell {
    width: 10%;
}

.badge {
    display: inline-block;
    padding: 0.1rem 0.2rem;
    margin: 0.1rem;
    border-radius: 0.3rem;
    background-color: grey;
}
