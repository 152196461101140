
.sidenav {
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    top: 55px;
    left: 0;
    background-color: lightgrey;
    border-right: 2px solid grey;
    overflow-x: hidden;
    padding-top: 0px;
    align-items: center;
}

.sidenav-item {
    height: 50px;
    vertical-align: middle;
    text-align: center;
    line-height: 50px;
    font-size: 15px;
}

.selected {
    background-color: darkgrey;
}
