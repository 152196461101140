
.datapoint-table-name-data-cell {
    width: 8%;
}
.datapoint-table-description-data-cell {
    width: 14%;
}
.datapoint-table-description-input {
    font-size: 1rem;
    box-sizing: border-box;
    width: 95%;
}
.datapoint-table-rate-data-cell {
    width: 5%;
}
.datapoint-table-range-min-data-cell {
    width: 5%;
}
.datapoint-table-range-max-data-cell {
    width: 5%;
}
.datapoint-table-msb-data-cell {
    width: 5%;
}
.datapoint-table-length-data-cell {
    width: 5%;
}
.datapoint-table-gain-data-cell {
    width: 5%;
}
.datapoint-table-units-data-cell {
    width: 12%;
    padding: 0.2rem;
}
.datapoint-table-can-id-data-cell {
    width: 8%;
    padding: 0.2rem;
}
.datapoint-table-transmission-method-data-cell {
    width: 12%;
    padding: 0.2rem;
}
.datapoint-table-ecu-data-cell {
    width: 8%;
    padding: 0.2rem;
}
