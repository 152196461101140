.health-card {
    float: left;
    background-color: lightgrey;
    border: 2px solid grey;
    width: 90%;
    margin: 10px;
    padding: 20px;
}

.health-status-light-container {
    float: left;
    width: 20%;
}

.circle {
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    border-radius: 50%;
}

.health-status-light-neutral {
    background: grey;
}

.health-status-light-good {
    background: green;
}

.health-status-light-okay {
    background: gold;
}

.health-status-light-bad {
    background: red;
}

.circle h3 {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.label {
    float: left;
    width: 80%;
    text-align: left;
    line-height: 100%;
}
